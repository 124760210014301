/** @jsx jsx */
import { jsx, Button, Flex } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import { Global } from "@emotion/core"
import React, { useState, useEffect } from "react"
import NavMenuDesktop from "../components/navMenuDesktop"
import NavMenuMobile from "../components/navMenuMobile"
import { Masonry } from "masonic"
import Img from "gatsby-image"
import Modal from "react-modal"
import {
  FaTimes,
  FaInfoCircle,
  FaMicrophone,
  FaPlayCircle,
  FaCamera,
} from "react-icons/fa"
import Footer from "../components/footer"
import { Link } from "gatsby"
import ReactPlayer from "react-player/lazy"
import SEO from "../components/seo"

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
}
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby")

const PortfolioPage = ({ data }) => {
  const gridItems = data.portfolio.frontmatter.grid
  const [scrollPosition, setSrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setSrollPosition(position)
    let scrollTop = window.scrollY
    let docHeight = document.body.offsetHeight
    let winHeight = window.innerHeight
    let scrollPercent = scrollTop / (docHeight - winHeight)
    let scrollPercentRounded = Math.round(scrollPercent * 100)
    //console.log(window.scrollY)
    // const navBarBottom = document.getElementsByClassName('navbar-bottom')[0];
    // if (scrollPercentRounded >= 80) {
    //   navBarBottom.classList.add('bottom');
    // } else {
    //   navBarBottom.classList.remove('bottom');
    // }
    //get bottom of grid
    const grid = document.getElementsByClassName("masonry-grid")[0]
    const gridTop = grid.offsetTop
    const gridOffset = grid.offsetHeight
    const gridBottom = gridTop + gridOffset
    const scrollBottom = window.scrollY + window.innerHeight
    //console.log(`gridBottom=${gridBottom} scrollBottom=${scrollBottom}`);
    const navBarBottom = document.getElementsByClassName("navbar-bottom")[0]
    if (scrollBottom > gridBottom) {
      navBarBottom.classList.add("bottom")
    } else {
      navBarBottom.classList.remove("bottom")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <Global
        styles={theme => ({
          ".ReactModal__Body--open": {
            overflow: "hidden",
          },
        })}
      />
      <SEO title="Portfolio" />
      <NavMenuMobile />
      <main
        className="portfolio-main"
        sx={{
          px: 3,
          py: 2,
          // bg: lighten("primary", 0.35),
          bg: "primaryBG",
          minHeight: "100vh",
        }}
      >
        <h1
          sx={{
            mt: ["75px", 0],
            color: "primary",
          }}
        >
          {data.portfolio.frontmatter.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.portfolio.html,
          }}
        />
        <Masonry
          items={gridItems}
          render={MasonryCard}
          columnGutter={8}
          columnWidth={300}
          className="masonry-grid"
        />

        <NavMenuDesktop navPosition="bottom" />
      </main>

      <Footer navPosition="bottom" />
    </>
  )
}

const MasonryCard = ({
  index,
  data: { title, image, credit, type, production_rel, video },
  width,
}) => {
  const [modalState, setModalState] = useState(false)
  const openModal = src => {
    setModalState(true)
    //stop bg scrolling when modal open - done in global css above now
  }
  const closeModal = () => {
    setModalState(false)
  }
  const height = `${width / image.childImageSharp.fluid.aspectRatio}px `
  console.log(height)
  return (
    <>
      <figure
        onClick={openModal}
        sx={{
          margin: 0,
          position: "relative",
          "& figcaption": {
            position: "relative",
            bg: "primary",
            color: "white",
            px: 2,
            py: 1,
            "& svg": {
              position: "absolute",
              top: "7px",
            },
            "& span": {
              pl: "22px",
            },
          },
          "@media (hover: hover)": {
            overflow: "hidden",
            "& figcaption": {
              position: "absolute",
              bottom: -30,
              opacity: 0.0,
              transition: "all 0.3s ease-out",
            },
            "&:hover": {
              cursor: "pointer",
              "& figcaption": {
                opacity: 1,
                bottom: 0,
              },
              "& > div svg": {
                opacity: "1",
              },
            },
          },
        }}
      >
        {type === "image" && (
          <>
            <Img
              fluid={image.childImageSharp.fluid}
              alt={credit}
              fadeIn={true}
              height={height}
              sx={{
                "& img": {
                  objectPosition: "top center !important",
                },
              }}
            />
            <figcaption>
              <FaCamera /> <span>{`${title} - ${credit}`}</span>
            </figcaption>
          </>
        )}
        {type === "production" && (
          <>
            <Img
              fluid={image.childImageSharp.fluid}
              alt={credit}
              fadeIn={true}
              height={height}
              sx={{
                "& img": {
                  objectPosition: "top center !important",
                },
              }}
            />
            <figcaption>
              <FaInfoCircle /> <span>{`${title} - ${credit}`}</span>
            </figcaption>
          </>
        )}
        {type === "video" && (
          <>
            <div>
              <FaPlayCircle
                sx={{
                  position: "absolute",
                  zIndex: 2,
                  top: "50%",
                  left: "50%",
                  mt: "-16px",
                  ml: "-16px",
                  color: "white",
                  fontSize: "2rem",
                  transition: "opacity 0.3s",
                  opacity: "0.5",
                }}
              />
            </div>
            <Img
              fluid={image.childImageSharp.fluid}
              alt={credit}
              fadeIn={true}
              height={height}
              sx={{
                "& img": {
                  objectPosition: "top !important",
                },
              }}
            />

            <figcaption>
              <FaPlayCircle /> <span>{`${title} - ${credit}`}</span>
            </figcaption>
          </>
        )}
      </figure>

      <Modal
        isOpen={modalState}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
        contentLabel="Image Lightbox Modal"
      >
        <figure
          sx={{
            width: "1000px",
            maxWidth: "90vw",
            maxHeight: "90vh",
            textAlign: "center",
          }}
        >
          <FaTimes
            onClick={closeModal}
            sx={{
              color: "#fff",
              fontSize: 4,
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              opacity: 0.7,
              zIndex: 10,
              "&:hover": {
                opacity: 1,
              },
            }}
          />

          {type !== "video" && (
            <Img
              fluid={image.childImageSharp.fluid}
              alt={title}
              sx={{
                //width: ['300px', '450px', '800px'],
                width: "100%",
                maxWidth: "95vw",
                height: "100%",
                maxHeight: "85vh",
                "& img": {
                  objectFit: "contain !important",
                },
              }}
            />
          )}
          {type === "video" && (
            <div
              className="player-wrapper"
              sx={{
                position: "relative",
                paddingTop: "56.25%",
                mb: 3,
              }}
            >
              <ReactPlayer
                className="react-player"
                url={video}
                width="100%"
                height="100%"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          )}
          <figcaption
            sx={{
              backgroundColor: "primary",
              color: "#fff",
              display: "inline-block",
              padding: 2,
              mt: 2,
            }}
          >
            {title} - <span>{credit}</span>
          </figcaption>
          {type === "production" && (
            <Link
              to={`/productions/${production_rel}`}
              sx={{
                bg: "transparent",
                fontSize: "1.7em",
                color: "white",
                textDecoration: "none",
                px: 2,
                py: 2,
                marginLeft: 2,
                position: "relative",
                display: "inline-flex",
                alignSelf: "center",
                "&:hover, &:focus": {
                  color: "primary",
                },
              }}
            >
              <div
                sx={{
                  position: "absolute",
                  top: "-3px",
                  "& span": {
                    fontSize: "16px",
                    ml: 2,
                    pt: 1,
                  },
                }}
              >
                <Flex>
                  <FaInfoCircle /> <span>More</span>
                </Flex>
              </div>
            </Link>
          )}
        </figure>
      </Modal>
    </>
  )
}

export default PortfolioPage

export const query = graphql`
  query {
    portfolio: markdownRemark(
      frontmatter: { templateKey: { eq: "page-portfolio" } }
    ) {
      id
      frontmatter {
        title
        grid {
          type
          title
          credit
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
                aspectRatio
              }
            }
          }
          production_rel
          url
          video
        }
      }
      html
    }

    productions: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "production" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            excerpt
            title
            feat_img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
